import {
    fetchGet,
    fetchPut,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModuleNames, AccessPermissionModules } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}campaignservice`;
const CAMPAIGNS_BASE_URL = `${BASE_URL}/campaigns`;

// TODO: List params below.
// * Query params:
const getCampaigns = (queryObj) =>
    fetchGet(
        `${CAMPAIGNS_BASE_URL}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.ListCampaigns
        )
    );

const getCampaignReport = (queryObj) =>
    fetchGet(
        `${CAMPAIGNS_BASE_URL}/report?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.GetCampaignReport
        )
    );

const createCampaign = (payload) =>
    fetchPost(
        `${CAMPAIGNS_BASE_URL}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.CreateCampaign
        )
    );

const getCampaignById = (campaignId) =>
    fetchGet(
        `${CAMPAIGNS_BASE_URL}/${campaignId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.GetCampaign
        )
    );

const updateCampaignById = (campaignId, payload) =>
    fetchPut(
        `${CAMPAIGNS_BASE_URL}/${campaignId}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.UpdateCampaign
        )
    );

const archiveCampaign = (campaignId) =>
    fetchDelete(
        `${CAMPAIGNS_BASE_URL}/${campaignId}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CAMPAIGNS,
            AccessPermissionModules[AccessPermissionModuleNames.CAMPAIGNS]
                .actions.DeleteCampaign
        )
    );

export {
    getCampaigns,
    getCampaignById,
    createCampaign,
    updateCampaignById,
    archiveCampaign,
    getCampaignReport,
};
