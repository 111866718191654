const JobTypesStatus = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};

const JobStatus = {
    COMPLETED: "COMPLETED",
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};

const ProcessType = {
    IMMEDIATE: "IMMEDIATE",
    SCHEDULED: "SCHEDULED",
};

const ScheduleType = {
    ONCE: "ONCE",
    RECURRING: "RECURRING",
};

const RecurrenceMethod = {
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
};

const RecurrenceType = {
    DAY_OF_MONTH: "DAY_OF_MONTH",
    END_OF_MONTH: "END_OF_MONTH",
};

const NotificationsStatus = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};

const JobStatusTextColorCodes = {
    [JobStatus.DISABLED]: "text-warning",
    [JobStatus.ENABLED]: "text-success",
};

export {
    JobTypesStatus,
    JobStatus,
    ProcessType,
    ScheduleType,
    RecurrenceMethod,
    RecurrenceType,
    NotificationsStatus,
    JobStatusTextColorCodes,
};
