import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
    SideNavigationBar,
    Accordion,
    Nav,
} from "@shoutout-labs/shoutout-themes-enterprise";
import {
    faBell,
    faPostcard,
    faMoneyBillStack,
    faDollarSign,
    faHistoryAlt,
    faFileImport,
    faSyncExclamation,
    faChart,
    faSetting,
    faLockAccess,
    faShare,
    faUsers,
    faCoins,
    faGift,
    faPlusCircle,
    faStore,
    faMedal,
    faSearch,
    faLock,
    faSuitcase,
    faUilTelegramAlt,
} from "FaICIconMap";
import { DataContext, UserContext } from "Contexts";
import {
    AccessPermissionModules,
    AccessPermissionModuleNames,
    UserBoundaryType,
} from "Data";
import { toReplaceSpaceWithHyphen } from "Utils";
import NavigationLink from "./NavigationLink";

import "./Navigation.scss";

const CustomSidebarMainToggle = ({ eventKey, icon, onSelect }) => {
    const onClick = useCallback(() => {
        onSelect(eventKey);
    }, [eventKey, onSelect]);
    return (
        <SideNavigationBar.NavLink
            icon={icon}
            name={eventKey}
            onClick={onClick}
        />
    );
};

const activeNavList = (pathname) => {
    switch (pathname) {
        case "members":
        case "find-members":
            return {
                activeMainTab: "Members",
                activeEventKey: "",
            };
        case "cards":
            return {
                activeMainTab: "Loyalty",
                activeEventKey: "Cards",
            };
        case "transactions":
            return {
                activeMainTab: "Loyalty",
                activeEventKey: "Transactions",
            };
        case "redemptions":
            return {
                activeMainTab: "Loyalty",
                activeEventKey: "Redemptions",
            };
        case "merchants":
        case "point-rules":
        case "affinity-groups":
        case "tiers":
        case "charity-list":
            return {
                activeMainTab: "Loyalty",
                activeEventKey: "",
            };

        case "fraudulence":
            return {
                activeMainTab: "Loyalty",
                activeEventKey: "Fraudulence",
            };
        case "notifications":
            return {
                activeMainTab: "Campaign",
                activeEventKey: "Notifications",
            };
        case "campaigns":
            return {
                activeMainTab: "Campaign",
                activeEventKey: "Campaigns",
            };
        case "analytics":
            return {
                activeMainTab: "Analytics",
                activeEventKey: "Analytics",
            };
        case "jobs":
            return {
                activeMainTab: "Analytics",
                activeEventKey: "",
            };
        case "control-panel":
            return {
                activeMainTab: "Control Panel",
                activeEventKey: "",
            };
        default:
            return null;
    }
};

const Navigation = ({ activeMainTab, setActiveMainTab }) => {
    const location = useLocation();
    const history = useHistory();
    const { isAuthorizedForAction, userBoundaryType } = useContext(UserContext);
    const { rewards = [], isLoadingPartnerRewards } = useContext(DataContext);
    const [activeEventKey, setActiveEventKey] = useState("");

    const navList = useMemo(() => {
        const isNotAMerchantUser =
            userBoundaryType !== UserBoundaryType.MERCHANT;

        switch (activeMainTab) {
            case "Members":
                return [
                    {
                        tab: "Members",
                        path: "/members",
                        icon: faUsers,
                    },
                    {
                        tab: "Find Members",
                        path: "/find-members",
                        icon: faSearch,
                    },
                ];
            case "Loyalty":
                return [
                    {
                        tab: "Cards",
                        path: "/cards",
                        icon: faPostcard,
                        secondaryLinks: [
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.CARD,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.CARD
                                ].actions.ListCards
                            )
                                ? [
                                    {
                                        tab: "Card Pool",
                                        path: "/cards/pool",
                                        icon: faMoneyBillStack,
                                    },
                                ]
                                : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.CARD,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.CARD
                                ].actions.CreateCardBatchJob
                            ) ||
                            isAuthorizedForAction(
                                AccessPermissionModuleNames.CARD,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.CARD
                                ].actions.ListCardBatchJobs
                            )
                                ? [
                                    {
                                        tab: "Instant Cards",
                                        path: "/cards/instant-cards",
                                        icon: faPlusCircle,
                                    },
                                    {
                                        tab: "Digital Cards",
                                        path: "/cards/digital-cards",
                                        icon: faPlusCircle,
                                    },
                                    {
                                        tab: "Embossed Cards",
                                        path: "/cards/embossed-cards",
                                        icon: faPlusCircle,
                                    },
                                ]
                                : []),
                        ],
                    },
                    {
                        tab: "Transactions",
                        path: "/transactions",
                        icon: faDollarSign,
                        secondaryLinks: [
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.TRANSACTION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.TRANSACTION
                                ].actions.ListTransactions
                            )
                                ? [
                                    {
                                        tab: "Transaction History",
                                        path: "/transactions/history",
                                        icon: faHistoryAlt,
                                    },
                                ]
                                : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.TRANSACTION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.TRANSACTION
                                ].actions.ListTransactionImportJobs
                            ) ||
                            isAuthorizedForAction(
                                AccessPermissionModuleNames.TRANSACTION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.TRANSACTION
                                ].actions.CreateTransactionImportJob
                            )
                                ? [
                                    {
                                        tab: "Import Transactions",
                                        path: "/transactions/import",
                                        icon: faFileImport,
                                    },
                                ]
                                : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.TRANSACTION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.TRANSACTION
                                ].actions.ListStagedTransaction
                            )
                                ? [
                                    {
                                        tab: "Invalid Transactions",
                                        path: "/transactions/invalid",
                                        icon: faSyncExclamation,
                                    },
                                ]
                                : []),
                            // {
                            //     tab: "Anomaly Transactions",
                            //     path: "/transactions/anomaly",
                            //     icon: faExclamationTriangle,
                            // },
                        ],
                    },
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.REWARD,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.REWARD
                        ].actions.ListRewards
                    )
                        ? [
                            {
                                tab: "Redemptions",
                                path: "/redemptions",
                                icon: faGift,
                                secondaryLinks: [
                                    {
                                        tab: "Rewards",
                                        path: "/redemptions/rewards",
                                        icon: faGift,
                                    },
                                    // * Partner rewards only have the redemption log atm.
                                    ...(isAuthorizedForAction(
                                        AccessPermissionModuleNames.REWARD,
                                        AccessPermissionModules[
                                            AccessPermissionModuleNames.REWARD
                                        ].actions.ListRewardRedemptionLogs
                                    ) ? [
                                        ...(isLoadingPartnerRewards
                                            ? [
                                                {
                                                    tab: "Loading...",
                                                    path: "",
                                                    icon: null,
                                                },
                                            ]
                                            : []),
                                    ] : rewards.map((reward) => ({
                                        tab: reward?.name,
                                        path: `/redemptions/${toReplaceSpaceWithHyphen(
                                            reward?.name
                                        )}`,
                                        icon: faGift,
                                    }))),
                                ],
                            },
                        ]
                        : []),
                    {
                        tab: "Merchants",
                        path: "/merchants",
                        icon: faStore,
                    },
                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.POINT_RULES,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.POINT_RULES
                        ].actions.ListPointRules
                    )
                        ? [
                            {
                                tab: "Point Rules",
                                path: "/point-rules",
                                icon: faCoins,
                            },
                        ]
                        : []),

                    {
                        tab: "Affinity Groups",
                        path: "/affinity-groups",
                        icon: faMedal,
                    },

                    ...(isAuthorizedForAction(
                        AccessPermissionModuleNames.TIER,
                        AccessPermissionModules[
                            AccessPermissionModuleNames.TIER
                        ].actions.ListTiers
                    )
                        ? [
                            {
                                tab: "Tiers",
                                path: "/tiers",
                                icon: faMedal,
                            },
                        ]
                        : []),
                    {
                        tab: "Fraudulence",
                        path: "/fraudulence",
                        icon: faLock,
                        secondaryLinks: [
                            {
                                tab: "Fraud Prevention",
                                path: "/fraudulence/fraud",
                                icon: faUsers,
                            },
                            {
                                tab: "Audit Logs",
                                path: "/fraudulence/audit-log",
                                icon: faCoins,
                            },
                        ],
                    },
                    {
                        tab: "Charity List",
                        path: "/charity-list",
                        icon: faShare,
                    },
                ];
            /*            case "Offers":
                return [
                    {
                        tab: "Charity List",
                        path: "/charity-list",
                        icon: faShare,
                    },
                ];*/
            case "Campaign":
                return [
                    {
                        tab: "Notifications",
                        path: "/notifications",
                        icon: faBell,
                        secondaryLinks: [
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.NOTIFICATION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.NOTIFICATION
                                ].actions.ListMessageLogs
                            )
                                ? [
                                    {
                                        tab: "Message Logs",
                                        path: "/notifications/message-logs",
                                        icon: faHistoryAlt,
                                    },
                                ]
                                : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.NOTIFICATION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.NOTIFICATION
                                ].actions.ListNotificationLogs
                            )
                                ? [
                                    {
                                        tab: "Notification Logs",
                                        path: "/notifications/notification-logs",
                                        icon: faHistoryAlt,
                                    },
                                ]
                                : []),
                            ...(isAuthorizedForAction(
                                AccessPermissionModuleNames.NOTIFICATION,
                                AccessPermissionModules[
                                    AccessPermissionModuleNames.NOTIFICATION
                                ].actions.ListNotifications
                            )
                                ? [
                                    {
                                        tab: "Templates",
                                        path: "/notifications/templates",
                                        icon: faFileImport,
                                    },
                                ]
                                : []),
                        ],
                    },
                    {
                        tab: "Campaigns",
                        path: "/campaigns",
                        icon: faUilTelegramAlt,
                        secondaryLinks: [
                            {
                                tab: "Email",
                                path: "/campaigns/email",
                                icon: faCoins,
                            },
                            {
                                tab: "SMS",
                                path: "/campaigns/sms",
                                icon: faUsers,
                            },
                        ],
                    },
                ];
            case "Analytics":
                return [
                    {
                        tab: "Analytics",
                        path: "/analytics",
                        icon: faChart,
                        secondaryLinks: [
                            {
                                tab: "Members",
                                path: "/analytics/members",
                                icon: faUsers,
                            },
                            {
                                tab: "Points",
                                path: "/analytics/points",
                                icon: faCoins,
                            },
                            {
                                tab: "Rewards",
                                path: "/analytics/rewards",
                                icon: faGift,
                            },
                            {
                                tab: "Cards",
                                path: "/analytics/cards",
                                icon: faPostcard,
                            },
                            {
                                tab: "Tiers",
                                path: "/analytics/tiers",
                                icon: faStore,
                            },
                            {
                                tab: "Merchants",
                                path: "/analytics/merchants",
                                icon: faStore,
                            },
                        ],
                    },
                    {
                        tab: "Jobs",
                        path: "/jobs",
                        icon: faSuitcase,
                    },
                ];
            case "Control Panel":
                return [
                    {
                        tab: "Access Control",
                        path: "/control-panel/access-control",
                        icon: faLockAccess,
                    },
                    ...(isNotAMerchantUser
                        ? [
                            {
                                tab: "Settings",
                                path: "/control-panel/settings/members",
                                icon: faSetting,
                            },
                        ]
                        : []),
                ];
            default:
                return null;
        }
    }, [
        userBoundaryType,
        isAuthorizedForAction,
        rewards,
        isLoadingPartnerRewards,
        activeMainTab,
    ]);

    const navigateTo = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(e.currentTarget.getAttribute("href"));
        },
        [history]
    );

    const onSetActiveEventKey = useCallback(
        (eventKey) => {
            setActiveEventKey(eventKey !== activeEventKey ? eventKey : "");
        },
        [setActiveEventKey, activeEventKey]
    );

    useEffect(() => {
        const pathname = location.pathname.split("/");
        if (pathname.length > 0) {
            if (activeNavList(pathname[1])) {
                setActiveMainTab(
                    activeNavList(pathname[1] || "").activeMainTab
                );
                setActiveEventKey(
                    activeNavList(pathname[1] || "").activeEventKey
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (
        // <div className="sidebar-wrapper bg-white">
        <div className="sidebar-wrapper float-left">
            <SideNavigationBar className="side-navigation">
                <div className="side-navigation-header">{activeMainTab}</div>
                <Accordion activeKey={activeEventKey}>
                    {navList.map((item) => {
                        if (item.secondaryLinks) {
                            return (
                                <div key={item.tab} className="secondary-menu">
                                    <CustomSidebarMainToggle
                                        eventKey={item.tab}
                                        onSelect={onSetActiveEventKey}
                                        icon={item.icon}
                                        activeKey={activeEventKey}
                                    />
                                    <Accordion.Collapse eventKey={item.tab}>
                                        <div>
                                            {item.secondaryLinks.map(
                                                ({ tab, path }) => (
                                                    <Nav.Item key={path}>
                                                        <Nav.Link
                                                            href={path}
                                                            active={
                                                                location.pathname ===
                                                                path
                                                            }
                                                            onClick={navigateTo}
                                                            disabled={
                                                                item?.tab ===
                                                                    "Redemptions" &&
                                                                tab ===
                                                                    "Loading..." &&
                                                                isLoadingPartnerRewards &&
                                                                true
                                                            }
                                                        >
                                                            {tab}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            )}
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            );
                        }
                        return (
                            <NavigationLink
                                path={item.path}
                                tab={item.tab}
                                icon={item.icon}
                                activePath={location.pathname}
                                key={item.path}
                            />
                        );
                    })}
                </Accordion>
            </SideNavigationBar>
        </div>
    );
};

export default Navigation;
