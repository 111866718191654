const CardTypes = {
    DIGITAL_CARD: "DIGITAL_CARD",
    INSTANT_CARD: "INSTANT_CARD",
    EMBOSSED_CARD: "EMBOSSED_CARD",
    KEY_TAG: "KEY_TAG",
    REGULAR_CARD: "REGULAR_CARD",
    REGULAR_CARD_AND_KEY_TAG: "REGULAR_CARD_AND_KEY_TAG",
};

const CardTypeBadgeVariants = {
    DIGITAL_CARD: "purple",
    INSTANT_CARD: "teal",
    EMBOSSED_CARD: "warning",
    KEY_TAG: "info",
    REGULAR_CARD: "primary",
    REGULAR_CARD_AND_KEY_TAG: "secondary",
};

const DigitalCardGenerationOptions = { MANUAL: "MANUAL", AUTO: "AUTO" };

export { CardTypes, CardTypeBadgeVariants, DigitalCardGenerationOptions };
