import React from "react";
import { toast } from "react-toastify";
import {
    BasicConfig,
    Utils as QbUtils,
    Widgets,
} from "@shoutout-labs/react-awesome-query-builder-shoutout";
import { IdTypeSelector, IdentificationTypes } from "Data";
import TimeInput from "Components/common/timeOfDayPicker/TimeInput";
import MonthPicker from "Components/common/monthPicker/MonthPicker";
import BooleanValuePicker from "Components/common/booleanValuePicker/BooleanValuePicker";
import { MonthsOptions } from "./DateUtils";
import {
    capitalizeOnlyFirstLetterOfString,
    toTitleCaseFromSnakeAndCamel,
} from "./StringUtils";

const { VanillaTextWidget, VanillaSelectWidget, VanillaDateWidget } = Widgets;

const booleanValueFields = [
    "isValidEmail",
    "isValidMobileNumber",
    "notificationPreference.allowPromotionalNotifications",
    "notificationPreference.preferredChannel",
];

const equalAndNotEqualOperators = ["equal", "not_equal"];

const existsAndNotExistsOperators = ["is_not_empty", "is_empty"];

const operatorsWithoutExistsAndNotExists = [
    "equal",
    "not_equal",
    "less",
    "less_or_equal",
    "greater",
    "greater_or_equal",
    "between",
    "not_between",
];

const equalNotEqualAndExistOperators = [
    ...equalAndNotEqualOperators,
    ...existsAndNotExistsOperators,
];

const equalAndExistOperators = equalNotEqualAndExistOperators.filter(
    (item) => item !== "not_equal"
);

const booleanFieldLabels = booleanValueFields.map((bVF) => {
    if (bVF.startsWith("notificationPreference.")) {
        const splitField = bVF.split(".");
        return splitField[1]
            ? toTitleCaseFromSnakeAndCamel(splitField[1])
            : "~ unknown";
    }

    return toTitleCaseFromSnakeAndCamel(bVF);
});

const fieldsOnlyNeedEqualAndExistsOperators = [
    "Country",
    "Country Code",
    "Gender",
    "Portal User Id",
    "Portal Username",
];

const fieldsWithoutsExistsNotExistsOperators = [
    "Points",
    "Card Replacement Count",
];

const setFieldSettings = ({ type = "", fieldSettings = null, label = "" }) => {
    const defaultEmptylistValue = {
        listValues: [
            {
                value: "0",
                title: `${
                    label
                        ? capitalizeOnlyFirstLetterOfString(label) + " not"
                        : "Not"
                } found`,
            },
        ],
    };
    if (
        type === "array" &&
        fieldSettings &&
        Array.isArray(fieldSettings) &&
        fieldSettings.length !== 0
    ) {
        return fieldSettings;
    } else if (type === "select" && fieldSettings) {
        return { listValues: fieldSettings };
    } else {
        return defaultEmptylistValue;
    }
};

const getCustomOperatorValues = (label = "") => {
    if (~fieldsOnlyNeedEqualAndExistsOperators.indexOf(label)) {
        return { operators: equalNotEqualAndExistOperators };
    } else if (~booleanFieldLabels.indexOf(label)) {
        return { operators: equalAndExistOperators };
    } else if (~fieldsWithoutsExistsNotExistsOperators.indexOf(label)) {
        return {
            operators: operatorsWithoutExistsAndNotExists,
        };
    } else {
        return {};
    }
};

delete BasicConfig?.operators["proximity"];

const getDateConfigurationsForInput = (operator = "") => {
    let placeholder = "Enter date";
    let min = "01";
    let max = "9999";
    let defaultValue = "00";

    switch (operator) {
        case "day_is":
        case "less_than_days":
        case "greater_than_days":
            placeholder = "Enter a day";
            max = "31";
            break;
        case "year_is":
        case "less_than_years":
        case "greater_than_years":
            placeholder = "Enter a year";
            max = "3000";
            break;
        default:
            break;
    }

    return { placeholder, min, max, defaultValue };
};

const getDateValue = ({
    value = "",
    name = "",
    operator = "",
    incrementValue = "",
    decrementValue = "",
}) => {
    const { min, max } = getDateConfigurationsForInput(operator);
    let dateValue = value?.toString().padStart(2, "0");

    switch (name) {
        case `${operator}-increment`: {
            dateValue = (Number(incrementValue) + 1)
                .toString()
                .padStart(2, "0");
            break;
        }
        case `${operator}-decrement`: {
            dateValue = (Number(decrementValue) - 1)
                .toString()
                .padStart(2, "0");
            break;
        }
        default:
            break;
    }

    if (Number(dateValue) < Number(min)) {
        return min;
    } else if (Number(dateValue) > Number(max)) {
        return max;
    } else {
        return dateValue;
    }
};

const getArrayValueMongoFormat = ({
    field = "",
    filter = "",
    isUnknownFitler = false,
}) => {
    try {
        switch (field) {
            case "nationalId":
            case "driverLicense":
            case "passport": {
                const identificationType = IdTypeSelector.find(
                    (iTS) => iTS.key === field
                ).value;

                if (isUnknownFitler) {
                    return {
                        "identifications.identificationType": {
                            $ne: identificationType,
                        },
                    };
                } else {
                    return {
                        identifications: {
                            $elemMatch: {
                                identificationType,
                                identificationNumber: filter,
                            },
                        },
                    };
                }
            }
            case "additionalPhoneNumbers": {
                if (isUnknownFitler) {
                    return {
                        additionalPhoneNumbers: {
                            $exists: true,
                            $type: "array",
                            $eq: [],
                        },
                    };
                } else {
                    return {
                        additionalPhoneNumbers: filter,
                    };
                }
            }
            case "pointsToExpire": {
                if (isUnknownFitler) {
                    return {
                        $or: [
                            {
                                pointsToExpire: {
                                    $exists: true,
                                    $type: "array",
                                    $eq: [],
                                },
                            },
                            {
                                "pointsToExpire.pointsToExpire": {
                                    $exists: false,
                                },
                            },
                        ],
                    };
                } else {
                    return {
                        pointsToExpire: {
                            $elemMatch: {
                                pointsToExpire: filter,
                            },
                        },
                    };
                }
            }
            case "pointsExpireOn": {
                // * Using "day", "month" and "year" only comparisons with date values within an array is complicated. Temporarily removed the filters mentioned below.
                // ? "year_is",
                // ? "day_is",
                // ? "month_is",
                // ? "less_than_years",
                // ? "greater_than_years",
                // ? "less_than_months",
                // ? "greater_than_months",
                // ? "less_than_days",
                // ? "greater_than_days",

                if (isUnknownFitler) {
                    return {
                        $or: [
                            {
                                pointsToExpire: {
                                    $exists: true,
                                    $type: "array",
                                    $eq: [],
                                },
                            },
                            {
                                "pointsToExpire.pointsExpireOn": {
                                    $exists: false,
                                },
                            },
                        ],
                    };
                } else {
                    return {
                        pointsToExpire: {
                            $elemMatch: {
                                pointsExpireOn: filter,
                            },
                        },
                    };
                }
            }
            default: {
                throw new Error("Invalid filter");
            }
        }
    } catch (error) {
        console.error(error);
        toast.error(
            <div>
                Failed to apply filter!
                <br />
                {error.message
                    ? `Error: ${error.message}`
                    : "Please try again later."}
            </div>
        );
    }
};

const getInitialConfig = ({ isLoading = false }) => {
    return {
        ...BasicConfig,
        operators: {
            ...BasicConfig.operators,
            ...(BasicConfig.operators.proximity && {}),
            equal: {
                ...BasicConfig.operators.equal,
                label: "Equals",
            },
            not_equal: {
                ...BasicConfig.operators.not_equal,
                label: "Not equals",
            },
            select_equals: {
                ...BasicConfig.operators.equal,
                label: "Equals",
            },
            select_not_equals: {
                ...BasicConfig.operators.not_equal,
                label: "Not equals",
            },
            like: {
                ...BasicConfig.operators.like,
                label: "Contains",
            },
            not_like: {
                ...BasicConfig.operators.not_like,
                label: "Not contains",
            },
            is_empty: {
                ...BasicConfig.operators.is_empty,
                label: "Is unknown",
            },
            is_not_empty: {
                ...BasicConfig.operators.is_not_empty,
                label: "Is known",
            },
            less: {
                ...BasicConfig.operators.less,
                label: "Less than",
            },
            less_or_equal: {
                ...BasicConfig.operators.less_or_equal,
                label: "Less than or equal",
            },
            greater: {
                ...BasicConfig.operators.greater,
                label: "Greater than",
            },
            greater_or_equal: {
                ...BasicConfig.operators.greater_or_equal,
                label: "Greater than or equal",
            },
            array_empty: {
                label: "Empty",
                reversedOp: "array_not_empty",
                labelForFormat: "NULL",
                cardinality: 0,
                formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
                    `${field} ${opDef.labelForFormat}`,
                mongoFormatOp: (field, op, value) => ({
                    [field]: { $exists: true, $size: 0 },
                }),
            },
            greater_than_days: {
                label: "Greater than days",
                labelForFormat: ">",
                jsonLogic: ">",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $gt: [
                                {
                                    $dayOfMonth: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            less_than_days: {
                label: "Less than days",
                labelForFormat: "<",
                jsonLogic: "<",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $lt: [
                                {
                                    $dayOfMonth: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            greater_than_months: {
                label: "Greater than months",
                labelForFormat: ">",
                jsonLogic: ">",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $gt: [
                                {
                                    $month: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            less_than_months: {
                label: "Less than months",
                labelForFormat: "<",
                jsonLogic: "<",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $lt: [
                                {
                                    $month: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            greater_than_years: {
                label: "Greater than years",
                labelForFormat: ">",
                jsonLogic: ">",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $gt: [
                                {
                                    $year: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            less_than_years: {
                label: "Less than years",
                labelForFormat: "<",
                jsonLogic: "<",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $lt: [
                                {
                                    $year: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            month_is: {
                label: "Month is",
                labelForFormat: "==",
                jsonLogic: "==",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $eq: [
                                {
                                    $month: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            day_is: {
                label: "Day is",
                labelForFormat: "==",
                jsonLogic: "==",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $eq: [
                                {
                                    $dayOfMonth: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            year_is: {
                label: "Year is",
                labelForFormat: "==",
                jsonLogic: "==",
                mongoFormatOp: (field, op, value) => {
                    return {
                        $expr: {
                            $eq: [
                                {
                                    $year: `$${field}`,
                                },
                                value,
                            ],
                        },
                    };
                },
            },
            array_not_empty: {
                label: "Not Empty",
                reversedOp: "array_empty",
                labelForFormat: "NOT NULL",
                cardinality: 0,
                formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
                    `${field} ${opDef.labelForFormat}`,
                mongoFormatOp: (field, op, value) => ({
                    [field]: { $exists: true, $not: { $size: 0 } },
                }),
            },
            array_value_equal: {
                ...BasicConfig.operators.equal,
                label: "Equal",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: value,
                    });
                },
            },
            array_value_not_equal: {
                ...BasicConfig.operators.not_equal,
                label: "Not equal",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: {
                            $ne: value,
                        },
                    });
                },
            },
            array_value_less: {
                ...BasicConfig.operators.less,
                label: "Less",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: { $lt: value },
                    });
                },
            },
            array_value_less_or_equal: {
                ...BasicConfig.operators.less_or_equal,
                label: "Less than or equal",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: { $lte: value },
                    });
                },
            },
            array_value_greater: {
                ...BasicConfig.operators.greater,
                label: "Greater",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: { $gt: value },
                    });
                },
            },
            array_value_greater_or_equal: {
                ...BasicConfig.operators.greater_or_equal,
                label: "Greater than or equal",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        filter: { $gte: value },
                    });
                },
            },
            array_value_between: {
                ...BasicConfig.operators.between,
                label: "Between",
                mongoFormatOp: (field, op, value) => {
                    const [fromValue = 0, toValue = 0] = value;

                    const filter = { $gte: fromValue, $lte: toValue };

                    return getArrayValueMongoFormat({ field, filter });
                },
            },
            array_value_is_not_between: {
                label: "Not between",
                ...BasicConfig.operators.not_between,
                mongoFormatOp: (field, op, value) => {
                    const [fromValue = 0, toValue = 0] = value;

                    const filter = { $not: { $gte: fromValue, $lte: toValue } };

                    return getArrayValueMongoFormat({ field, filter });
                },
            },
            array_value_is_known: {
                ...BasicConfig.operators.is_not_empty,
                label: "Is known",
                mongoFormatOp: (field, op, value) => {
                    let filter = { $exists: true };

                    if (field === "additionalPhoneNumbers") {
                        filter = {
                            $exists: true,
                            $type: "array",
                            $ne: [],
                        };
                    }

                    return getArrayValueMongoFormat({ field, filter });
                },
            },
            array_value_is_unknown: {
                ...BasicConfig.operators.is_empty,
                label: "Is unknown",
                mongoFormatOp: (field, op, value) => {
                    return getArrayValueMongoFormat({
                        field,
                        isUnknownFitler: true,
                    });
                },
            },
        },
        types: {
            ...BasicConfig.types,
            date: {
                ...BasicConfig.types.date,
                widgets: {
                    ...BasicConfig.types.date.widgets,
                    date: {
                        operators: [
                            ...BasicConfig.types.date.widgets.date.operators,
                            "year_is",
                            "day_is",
                            "month_is",
                            "less_than_years",
                            "greater_than_years",
                            "less_than_months",
                            "greater_than_months",
                            "less_than_days",
                            "greater_than_days",
                            "array_value_is_known",
                            "array_value_is_unknown",
                            "array_value_less",
                            "array_value_less_or_equal",
                            "array_value_greater",
                            "array_value_greater_or_equal",
                            "array_value_between",
                            "array_value_is_not_between",
                        ],
                    },
                },
            },
            number: {
                ...BasicConfig.types.number,
                widgets: {
                    ...BasicConfig.types.number.widgets,
                    number: {
                        operators: [
                            ...BasicConfig.types.number.widgets.number
                                .operators,
                            "array_value_equal",
                            "array_value_not_equal",
                            "array_value_is_known",
                            "array_value_is_unknown",
                            "array_value_less",
                            "array_value_less_or_equal",
                            "array_value_greater",
                            "array_value_greater_or_equal",
                            "array_value_between",
                            "array_value_is_not_between",
                        ],
                    },
                },
            },
            text: {
                ...BasicConfig.types.text,
                widgets: {
                    ...BasicConfig.types.text.widgets,
                    text: {
                        operators: [
                            ...BasicConfig.types.text.widgets.text.operators,
                            "array_value_equal",
                            "array_value_not_equal",
                            "array_value_is_known",
                            "array_value_is_unknown",
                        ],
                    },
                },
            },
        },
        widgets: {
            ...BasicConfig.widgets,
            text: {
                ...BasicConfig.widgets.text,
                factory: (props) => {
                    if (~booleanValueFields.indexOf(props.field)) {
                        const onChange = (e) => {
                            try {
                                const value =
                                    e.currentTarget.dataset.radioValue ||
                                    undefined;
                                props?.setValue(value);
                            } catch (e) {
                                console.error(e);
                                toast.error(
                                    <div>
                                        {`Failed to set "${
                                            props.field || "current field's"
                                        }" "${
                                            props.operator || "~ unknown"
                                        }" value!`}
                                        <br />
                                        {e.message
                                            ? `Error: ${e.message}`
                                            : "Please try again later."}
                                    </div>
                                );
                            }
                        };
                        return (
                            <BooleanValuePicker
                                name={props.field}
                                value={props?.value}
                                disabled={isLoading}
                                onChange={onChange}
                            />
                        );
                    } else {
                        return (
                            <VanillaTextWidget
                                allowNew={true}
                                clearButton
                                {...props}
                            />
                        );
                    }
                },
            },
            select: {
                ...BasicConfig.widgets.select,
                factory: (props) => (
                    <div
                        className={`${
                            ~[
                                "merchantLocationId",
                                "residentialAddress.city",
                                "residentialAddress.stateOrProvince",
                            ].indexOf(props.field)
                                ? "merchant-location"
                                : ""
                        }`}
                    >
                        <VanillaSelectWidget {...props} />
                    </div>
                ),
            },
            date: {
                type: "date",
                valueSrc: "value",
                dateFormat: "YYYY-MM-DD",
                valueFormat: "YYYY-MM-DD",
                value: "",
                factory: (props) => {
                    props.placeholder = "Select date";
                    props.placeholders = "Select date";

                    switch (props.operator) {
                        case "day_is":
                        case "less_than_days":
                        case "greater_than_days":
                        case "year_is":
                        case "less_than_years":
                        case "greater_than_years": {
                            const { placeholder, min, max, defaultValue } =
                                getDateConfigurationsForInput(props.operator);
                            props.placeholder = placeholder;
                            props.placeholders = placeholder;

                            const onChangeDateValue = (e) => {
                                try {
                                    const name = e.currentTarget.name;
                                    let val = e.target.value;
                                    if (val === "" || val === null)
                                        val = undefined;
                                    else val = Number(val);

                                    switch (props.operator) {
                                        case "day_is":
                                        case "less_than_days":
                                        case "greater_than_days":
                                        case "year_is":
                                        case "less_than_years":
                                        case "greater_than_years": {
                                            const dateValue = getDateValue({
                                                value: val,
                                                name,
                                                operator: props.operator || "",
                                                incrementValue:
                                                    e.currentTarget.dataset
                                                        .incrementValue || "01",
                                                decrementValue:
                                                    e.currentTarget.dataset
                                                        .decrementValue || "01",
                                            });
                                            props?.setValue(dateValue);
                                            break;
                                        }
                                        default:
                                            props?.setValue(val);
                                            break;
                                    }
                                } catch (e) {
                                    console.error(e);
                                    toast.error(
                                        <div>
                                            {`Failed to set "${
                                                props.field || "current field's"
                                            }" "${
                                                props.operator || "date"
                                            }" value!`}
                                            <br />
                                            {e.message
                                                ? `Error: ${e.message}`
                                                : "Please try again later."}
                                        </div>
                                    );
                                }
                            };

                            return (
                                <TimeInput
                                    name={props.operator}
                                    min={min}
                                    max={max}
                                    value={props?.value || defaultValue}
                                    incDecButtonValue={
                                        props?.value || defaultValue
                                    }
                                    disabled={isLoading}
                                    buttonsBySide
                                    onChange={onChangeDateValue}
                                    onIncrement={onChangeDateValue}
                                    onDecrement={onChangeDateValue}
                                />
                            );
                        }
                        case "month_is":
                        case "less_than_months":
                        case "greater_than_months": {
                            props.placeholder = "Select date";
                            const selectedMonth = props?.value
                                ? MonthsOptions.filter(
                                      (mO) => mO.value === props.value
                                  )
                                : [];

                            const onSelectMonth = (e) => {
                                try {
                                    props?.setValue(e[0]?.value || "");
                                } catch (e) {
                                    console.error(e);
                                    toast.error(
                                        <div>
                                            {`Failed to set "${
                                                props.field || "current field's"
                                            }" "${
                                                props.operator || "date"
                                            }" value!`}
                                            <br />
                                            {e.message
                                                ? `Error: ${e.message}`
                                                : "Please try again later."}
                                        </div>
                                    );
                                }
                            };

                            return (
                                <MonthPicker
                                    selected={selectedMonth}
                                    disabled={isLoading}
                                    onChange={onSelectMonth}
                                />
                            );
                        }
                        default:
                            return <VanillaDateWidget {...props} />;
                    }
                },
            },
        },
        settings: {
            canReorder: !isLoading,
            canRegroup: !isLoading,
            immutableGroupsMode: isLoading,
            immutableFieldsMode: isLoading,
            immutableOpsMode: isLoading,
            immutableValuesMode: isLoading,
            maxNesting: 1, //* No groups.
            maxNumberOfRules: 6,
            showErrorMessage: true,
            showNot: false,
            clearValueOnChangeOp: true,
        },
    };
};

const buildMemberFilterConfig = ({
    contactAttributes,
    tags,
    isLoading = false,
}) => {
    const attributes = Object.entries(contactAttributes).reduce(
        (result, [key, value]) => {
            switch (value.type) {
                case "number": {
                    if (key === "pointsToExpire") {
                        result[key] = {
                            label: value.label,
                            type: "number",
                            operators: [
                                "array_value_equal",
                                "array_value_not_equal",
                                "array_value_is_known",
                                "array_value_is_unknown",
                                "array_value_less",
                                "array_value_less_or_equal",
                                "array_value_greater",
                                "array_value_greater_or_equal",
                                "array_value_between",
                                "array_value_is_not_between",
                            ],
                        };
                    } else {
                        result[key] = {
                            label: value.label,
                            type: "number",
                            ...getCustomOperatorValues(value.label),
                        };
                    }
                    break;
                }
                case "string": {
                    if (value.values && value.values.length > 0) {
                        result[key] = {
                            label: value.label,
                            type: "text",
                            useAsyncSearch: true,
                            useLoadMore: true,
                            forceAsyncSearch: false,
                            allowCustomValues: true,
                            asyncFetch: (search) => {
                                return {
                                    values: value.values.map((item) => {
                                        if (typeof item === "object") {
                                            return {
                                                title: item.label || item.value,
                                                value: item.value,
                                            };
                                        }
                                        return {
                                            title: item,
                                            value: item,
                                        };
                                    }),
                                    hasMore: false,
                                };
                            },
                            ...getCustomOperatorValues(value.label),
                        }; //TODO: Suggestions are there to show up which can't show without updating the widget
                    } else if (
                        ~Object.values(IdentificationTypes).indexOf(
                            value.value
                        ) ||
                        key === "additionalPhoneNumbers"
                    ) {
                        result[key] = {
                            label: value.label,
                            type: "text",
                            operators: [
                                "array_value_equal",
                                "array_value_not_equal",
                                "array_value_is_known",
                                "array_value_is_unknown",
                            ],
                        };
                    } else {
                        result[key] = {
                            label: value.label,
                            type: "text",
                            allowCustomValues: true,
                            ...getCustomOperatorValues(value.label),
                        };
                    }
                    break;
                }
                case "array": {
                    result[key] = {
                        label: value.label,
                        type: "select",
                        operators: [
                            "select_any_in",
                            "select_not_any_in",
                            "array_empty",
                            "array_not_empty",
                        ],
                        fieldSettings: setFieldSettings({
                            type: "array",
                            fieldSettings: value.fieldSettings,
                            label: value.label,
                        }),
                    };
                    break;
                }
                case "select": {
                    result[key] = {
                        label: value.label,
                        type: "select",
                        fieldSettings: setFieldSettings({
                            type: "select",
                            fieldSettings: value.fieldSettings,
                            label: value.label,
                        }),
                        ...(value?.operators
                            ? { operators: value.operators }
                            : {}),
                    };
                    break;
                }
                case "date": {
                    let operators = [
                        "less",
                        "less_or_equal",
                        "greater",
                        "greater_or_equal",
                        "between",
                        "not_between",
                        "year_is",
                        "day_is",
                        "month_is",
                        "less_than_years",
                        "greater_than_years",
                        "less_than_months",
                        "greater_than_months",
                        "less_than_days",
                        "greater_than_days",
                        "is_not_empty",
                    ];

                    if (value.label === "Birth Date") {
                        operators.push("is_empty");
                    } else if (value.label === "Points Expire On") {
                        // * Using "day", "month" and "year" only comparisons with date values within an array is complicated. Temporarily removed the filters mentioned below.
                        // ? "year_is",
                        // ? "day_is",
                        // ? "month_is",
                        // ? "less_than_years",
                        // ? "greater_than_years",
                        // ? "less_than_months",
                        // ? "greater_than_months",
                        // ? "less_than_days",
                        // ? "greater_than_days",

                        operators = [
                            "array_value_is_known",
                            "array_value_is_unknown",
                            "array_value_less",
                            "array_value_less_or_equal",
                            "array_value_greater",
                            "array_value_greater_or_equal",
                            "array_value_between",
                            "array_value_is_not_between",
                        ];
                    }

                    result[key] = {
                        label: value.label,
                        type: "date",
                        valueSources: ["value"],
                        operators,
                    };
                    break;
                }
                default: {
                    result[key] = { label: value.label, type: value.type };
                }
            }

            return result;
        },
        {}
    );
    if (attributes.tags && tags) {
        attributes.tags.fieldSettings = {
            listValues: tags.map(({ label, tag_key }) => ({
                title: label,
                value: label,
            })),
            showSearch: true,
        };
        attributes.tags.valueSources = ["value"];
    }

    const initialConfig = getInitialConfig({ isLoading });

    return { ...initialConfig, fields: attributes };
};

const getMongoDBQuery = (filters, config) => {
    const filterData = QbUtils.checkTree(QbUtils.loadTree(filters), config);

    return QbUtils.mongodbFormat(filterData, config);
};

const getSegmentFilters = ({
    appliedMemberFilters,
    filterConfig,
    extraFilter = {},
}) =>
    appliedMemberFilters
        .map((aMF) => {
            if (aMF) {
                let mongoQuery = getMongoDBQuery(aMF, filterConfig);

                if (Object.keys(extraFilter).length !== 0)
                    mongoQuery = { ...mongoQuery, ...extraFilter };

                return JSON.stringify(mongoQuery);
            } else return JSON.stringify({});
        })
        .filter((item) => item);

const generateUserAttribute = (projection) => {
    return projection.reduce((result, item) => {
        switch (item) {
            case "contact": {
                result.push("email");
                result.push("mobileNumber");
                break;
            }

            case "name": {
                result.push("firstName");
                result.push("lastName");
                result.push("loyaltyId");
                break;
            }

            default: {
                result.push(item);
            }
        }
        return result;
    }, []);
};

export {
    buildMemberFilterConfig,
    getMongoDBQuery,
    generateUserAttribute,
    getSegmentFilters,
};
