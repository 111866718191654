import { fetchGet, jsonToQueryParam } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

// const getCountSummary = (fromDate = '', toDate = '') => {
//     return fetchGet(BASE_URL + "dailyloyaltyreports/?select=count&fromDate=" + fromDate + "&toDate=" + toDate + "&location=default");
// }

// const getSummary = (fromDate = '', toDate = '') => {
//     return fetchGet(BASE_URL + "dailypeoplereports/summary?fromDate=" + fromDate + "&toDate=" + toDate);
// }

const getTopUsedRewards = (timePeriod) => {
    return fetchGet(`${BASE_URL}rewardsstats/topusedrewards/?skip=0&limit=5&${jsonToQueryParam(timePeriod)}`);
};

const getBranches = (timePeriod) => {
    return fetchGet(`${BASE_URL}salestats/topbranches?${jsonToQueryParam(timePeriod)}`);
};

const getSalesStat = (timePeriod) => {
    return fetchGet(`${BASE_URL}salestats?select=count&${jsonToQueryParam(timePeriod)}`);
};

// const getActiveMemberStat = (fromDate = '', toDate = '') => {
//     return fetchGet(BASE_URL + "dailypeoplereports/active?&fromDate=" + fromDate + "&toDate=" + toDate);
// }

export { getTopUsedRewards, getBranches, getSalesStat };


