const PermissionModules = {
    AFFINITY_GROUPS: "AffinityGroup",
    GROUPS: "Group",
    ORGANIZATION: "Organization",
    USERS: "User",
    MODULES: "Module",
    USER_DATASET: "UserDataset",
    USER_PERMISSION: "UserPermission",
    USER_ACTIVITY: "UserActivity",
    MEMBER: "Member",
    MERCHANT: "Merchant",
    CARD: "Card",
    TIER: "Tier",
    REWARD: "Reward",
    POINT: "Point",
    SETTING: "Setting",
    TRANSACTION: "Transaction",
    POINT_RULES: "PointRule",
    ATTRIBUTE: "Attribute",
    NOTIFICATION: "Notification",
    MEMBER_NOTE: "MemberNote",
    SUB_TRANSACTION_TYPE: "SubTransactionType",
    LOCATION: "Location",
    ACTIVITY: "Activity",
    SEGMENT: "Segment",
    EVENT: "Event",
    CARD_ANALYTICS: "CardAnalytics",
    MEMBER_ANALYTICS: "MemberAnalytics",
    MERCHANT_ANALYTICS: "MerchantAnalytics",
    POINT_ANALYTICS: "PointAnalytics",
    REWARD_ANALYTICS: "RewardAnalytics",
    TIER_ANALYTICS: "TierAnalytics",
    RULE: "Rules",
    RULE_TYPE: "RuleTypes",
    INCIDENT: "Incidents",
    CHARITIES: "Charity",
    JOBS: "Jobs",
    JOB_TYPES: "JobTypes",
    AUDIT_LOG: "AuditLog",
    KLIP: "Klip",
    JOB_EXECUTIONS: "JobExecutions",
    REGION: "Region",
    CAMPAIGNS: "Campaigns",
};

const PermissionModulesDetails = {
    [PermissionModules.AFFINITY_GROUPS]: {
        moduleName: "AffinityGroup",
        moduleDescription: "Affinity group management module",
        actions: {
            ListAffinityGroups: "ListAffinityGroups",
            GetAffinityGroup: "GetAffinityGroup",
            CreateAffinityGroup: "CreateAffinityGroup",
            UpdateAffinityGroup: "UpdateAffinityGroup",
            DeleteAffinityGroup: "DeleteAffinityGroup",
            ListAffinityGroupMemberImportJobs:
                "ListAffinityGroupMemberImportJobs",
            ListAffinityGroupMemberImportLogs:
                "ListAffinityGroupMemberImportLogs",
            ExportAffinityGroupMemberImportLogs:
                "ExportAffinityGroupMemberImportLogs",
            CreateAffinityGroupMemberImportJob:
                "CreateAffinityGroupMemberImportJob",
        },
    },
    [PermissionModules.GROUPS]: {
        moduleName: "Group",
        moduleDescription: "System group management module",
        actions: {
            ListGroups: "ListGroups",
            CreateGroup: "CreateGroup",
            UpdateGroup: "UpdateGroup",
            DeleteGroup: "DeleteGroup",
        },
    },
    [PermissionModules.ORGANIZATION]: {
        moduleName: "Organization",
        moduleDescription: "Organization management module",
        actions: {
            CreateOrganization: "CreateOrganization",
            GetOrganization: "GetOrganization",
            UpdateOrganization: "UpdateOrganization",
        },
    },
    [PermissionModules.USERS]: {
        moduleName: "User",
        moduleDescription: "System user management module",
        actions: {
            CreateUser: "CreateUser",
            GetUser: "GetUser",
            ListUsers: "ListUsers",
            UpdateUser: "UpdateUser",
            DeleteUser: "DeleteUser",
            ResetPassword: "ResetPassword",
        },
    },
    [PermissionModules.MODULES]: {
        moduleName: "Module",
        moduleDescription: "System modules",
        actions: {
            ListModules: "ListModules",
            CreateModule: "CreateModule",
            UpdateModule: "UpdateModule",
        },
    },
    [PermissionModules.USER_DATASET]: {
        moduleName: "UserDataset",
        moduleDescription: "UserDataset modules",
        actions: {
            ListDatasets: "ListDatasets",
            CreateDataset: "CreateDataset",
        },
    },
    [PermissionModules.USER_PERMISSION]: {
        moduleName: "UserPermission",
        moduleDescription: "UserPermission modules",
        actions: {
            ListUserPermissions: "ListUserPermissions",
            CreateUserPermission: "CreateUserPermission",
            UpdateUserPermission: "UpdateUserPermission",
            DeleteUserPermission: "DeleteUserPermission",
        },
    },
    [PermissionModules.USER_ACTIVITY]: {
        moduleName: "UserActivity",
        moduleDescription: "UserActivity modules",
        actions: {
            ListUserActivities: "ListUserActivities",
        },
    },
    [PermissionModules.MEMBER]: {
        moduleName: "Member",
        moduleDescription: "Member modules",
        actions: {
            ListMembers: "ListMembers",
            GetMember: "GetMember",
            CreateMember: "CreateMember",
            UpdateMember: "UpdateMember",
            ChangeType: "ChangeType",
            DeleteMember: "DeleteMember",
            ExportMember: "ExportMember",
            EraseMember: "EraseMember",
            ExportMembers: "ExportMembers",
            UpdateMemberAffinityGroup: "UpdateMemberAffinityGroup",
            UpdateMemberChannelVerification: "UpdateMemberChannelVerification",
        },
    },
    [PermissionModules.MERCHANT]: {
        moduleName: "Merchant",
        moduleDescription: "Merchant management module",
        actions: {
            ListMerchants: "ListMerchants",
            GetMerchant: "GetMerchant",
            CreateMerchant: "CreateMerchant",
            UpdateMerchant: "UpdateMerchant",
            DeleteMerchant: "DeleteMerchant",
        },
    },
    [PermissionModules.CARD]: {
        moduleName: "Card",
        moduleDescription: "Card modules",
        actions: {
            ListCards: "ListCards",
            GetCard: "GetCard",
            GenerateCards: "GenerateCards",
            ListCardBatchJobs: "ListCardBatchJobs",
            ListCardConfigurations: "ListCardConfigurations",
            UpdateCard: "UpdateCard",
            CreateEmbossedRequest: "CreateEmbossedRequest",
            CancelEmbossed: "CancelEmbossed",
            AssignCard: "AssignCard",
            CreateCardBatchJob: "CreateCardBatchJob",
            UpdateCardBatchJob: "UpdateCardBatchJob",
            CreateCardConfiguration: "CreateCardConfiguration",
            UpdateCardConfiguration: "UpdateCardConfiguration",
            ExportCardBatchJobs: "ExportCardBatchJobs",
        },
    },
    [PermissionModules.TIER]: {
        moduleName: "Tier",
        moduleDescription: "Tier modules",
        actions: {
            ListTiers: "ListTiers",
            CreateTier: "CreateTier",
            UpdateTier: "UpdateTier",
            DeleteTier: "DeleteTier",
        },
    },
    [PermissionModules.REWARD]: {
        moduleName: "Reward",
        moduleDescription: "Reward modules",
        actions: {
            ListRewards: "ListRewards",
            GetReward: "GetReward",
            ListRewardTopups: "ListRewardTopups",
            ListRewardRedemptionLogs: "ListRewardRedemptionLogs",
            ListRewardDistributionJobs: "ListRewardDistributionJobs",
            CreateReward: "CreateReward",
            CreateRewardTopup: "CreateRewardTopup",
            UpdateReward: "UpdateReward",
            CancelLogItem: "CancelLogItem",
            RefundLogItem: "RefundLogItem",
            UpdateLogItem: "UpdateLogItem",
            ClaimReward: "ClaimReward",
            CreateRewardDistributionJob: "CreateRewardDistributionJob",
            UpdateRewardDistributionJob: "UpdateRewardDistributionJob",
            UploadRewardTopupFile: "UploadRewardTopupFile",
            RedeemReward: "RedeemReward",
            DeleteReward: "DeleteReward",
            GetRewardRedemptionLog: "GetRewardRedemptionLog",
        },
    },
    [PermissionModules.POINT]: {
        moduleName: "Point",
        moduleDescription: "Point modules",
        actions: {
            RedeemPoints: "RedeemPoints",
            AdjustPoints: "AdjustPoints",
            TransferPoints: "TransferPoints",
            CollectPointsBill: "CollectPointsBill",
            CollectPointsAmount: "CollectPointsAmount",
        },
    },
    [PermissionModules.SETTING]: {
        moduleName: "Setting",
        moduleDescription: "Settings modules",
        actions: {},
    },
    [PermissionModules.TRANSACTION]: {
        moduleName: "Transaction",
        moduleDescription: "Transaction modules",
        actions: {
            ListTransactions: "ListTransactions",
            ListTransactionImportJobs: "ListTransactionImportJobs",
            ListStagedTransaction: "ListStagedTransactions",
            CreateTransactionImportJob: "CreateTransactionImportJob",
            UpdateStagedTransaction: "UpdateStagedTransaction",
            DeleteStagedTransaction: "DeleteStagedTransaction",
        },
    },
    [PermissionModules.POINT_RULES]: {
        moduleName: "PointRule",
        moduleDescription: "Point rule modules",
        actions: {
            ListPointRules: "ListPointRules",
            CreatePointRule: "CreatePointRule",
            UpdatePointRule: "UpdatePointRule",
            DeletePointRule: "DeletePointRule",
        },
    },
    [PermissionModules.ATTRIBUTE]: {
        moduleName: "Attribute",
        moduleDescription: "Attribute modules",
        actions: {
            ListAttributes: "ListAttributes",
        },
    },
    [PermissionModules.SUB_TRANSACTION_TYPE]: {
        moduleName: "SubTransactionType",
        moduleDescription: "Sub transaction management module",
        actions: {
            ListSubTransactionTypes: "ListSubTransactionTypes",
            CreateSubTransactionType: "CreateSubTransactionType",
            UpdateSubTransactionType: "UpdateSubTransactionType",
            DeleteSubTransactionType: "DeleteSubTransactionType",
        },
    },
    [PermissionModules.NOTIFICATION]: {
        moduleName: "Notification",
        moduleDescription: "Notification management module",
        actions: {
            ListNotifications: "ListNotifications",
            ListNotificationLogs: "ListNotificationLogs",
            GetNotification: "GetNotification",
            CreateNotification: "CreateNotification",
            UpdateNotification: "UpdateNotification",
            DeleteNotification: "DeleteNotification",
        },
    },
    [PermissionModules.MEMBER_NOTE]: {
        moduleName: "MemberNote",
        moduleDescription: "Member notes module",
        actions: {
            ListMemberNotes: "ListMemberNotes",
            CreateMemberNote: "CreateMemberNote",
            UpdateMemberNote: "UpdateMemberNote",
            DeleteMemberNote: "DeleteMemberNote",
        },
    },
    [PermissionModules.SEGMENT]: {
        moduleName: "Segment",
        moduleDescription: "Segment management module",
        actions: {
            ListSegments: "ListSegments",
            ListSegmentCaregories: "ListSegmentCategories",
            CreateSegment: "CreateSegment",
            UpdateSegment: "UpdateSegment",
            CreateSegmentCategory: "CreateSegmentCategory",
            UpdateSegmentCategory: "UpdateSegmentCategory",
            DeleteSegment: "DeleteSegment",
            DeleteSegmentCategory: "DeleteSegmentCategory",
        },
    },
    [PermissionModules.LOCATION]: {
        moduleName: "Location",
        moduleDescription: "Location management module",
        actions: {
            ListLocations: "ListLocations",
            GetLocation: "GetLocation",
            CreateLocation: "CreateLocation",
            UpdateLocation: "UpdateLocation",
            DeleteLocation: "DeleteLocation",
        },
    },
    [PermissionModules.ACTIVITY]: {
        moduleName: "Activity",
        moduleDescription: "Activity management module",
        actions: {
            ListActivities: "ListActivities",
            ExportActivities: "ExportActivities",
            CreateActivity: "CreateActivity",
        },
    },
    [PermissionModules.EVENT]: {
        moduleName: "Event",
        moduleDescription: "Event management module",
        actions: {
            ListEvents: "ListEvents",
            CreateEvent: "CreateEvent",
            UpdateEvent: "UpdateEvent",
        },
    },

    [PermissionModules.CARD_ANALYTICS]: {
        moduleName: "CardAnalytics",
        moduleDescription: "Card Analytics management module",
        actions: {
            ViewCardsSummary: "ViewCardsSummary",
            ViewCardProcessingStatusReport: "ViewCardProcessingStatusReport",
            ViewCardActivationReport: "ViewCardActivationReport",
            ExportCardActivationReport: "ExportCardActivationReport",
        },
    },
    [PermissionModules.MEMBER_ANALYTICS]: {
        moduleName: "MemberAnalytics",
        moduleDescription: "Member Analytics management module",
        actions: {
            ViewMemberRegistrationCounts: "ViewMemberRegistrationCounts",
            ViewMemberRegistrationSeries: "ViewMemberRegistrationSeries",
            ExportMemberRegistrationCounts: "ExportMemberRegistrationCounts",
            ExportMemberRegistrationSeries: "ExportMemberRegistrationSeries",
            ViewMemberAffinityGroupsCounts: "ViewMemberAffinityGroupsCounts",
            ViewMemberAffinityGroupsSeries: "ViewMemberAffinityGroupsSeries",
            ViewMemberTierCounts: "ViewMemberTierCounts",
            ViewMemberTierSeries: "ViewMemberTierSeries",
            ViewNewReturnMemberCounts: "ViewNewReturnMemberCounts",
            ViewNewReturnMemberSeries: "ViewNewReturnMemberSeries",
            ExportNewReturnMemberSeries: "ExportNewReturnMemberSeries",
        },
    },
    [PermissionModules.MERCHANT_ANALYTICS]: {
        moduleName: "MerchantAnalytics",
        moduleDescription: "Merchant Analytics management module",
        actions: {
            ViewMerchantsTransactionCollectionCount:
                "ViewMerchantsTransactionCollectionCount",
            ExportMerchantsTransactionCollectionCount:
                "ExportMerchantsTransactionCollectionCount",
            ViewMerchantsTransactionCollectionSeries:
                "ViewMerchantsTransactionCollectionSeries",
            ExportMerchantsTransactionCollectionSeries:
                "ExportMerchantsTransactionCollectionSeries",
            ViewMerchantsTransactionRedemptionCount:
                "ViewMerchantsTransactionRedemptionCount",
            ExportMerchantsTransactionRedemptionCount:
                "ExportMerchantsTransactionRedemptionCount",
            ViewMerchantsTransactionRedemptionSeries:
                "ViewMerchantsTransactionRedemptionSeries",
            ExportMerchantsTransactionRedemptionSeries:
                "ExportMerchantsTransactionRedemptionSeries",
            ViewMerchantsTransactionAdjustmentCount:
                "ViewMerchantsTransactionAdjustmentCount",
            ExportMerchantsTransactionAdjustmentCount:
                "ExportMerchantsTransactionAdjustmentCount",
            ViewMerchantsTransactionAdjustmentSeries:
                "ViewMerchantsTransactionAdjustmentSeries",
            ExportMerchantsTransactionAdjustmentSeries:
                "ExportMerchantsTransactionAdjustmentSeries",
            ViewMerchantsPointMovements: "ViewMerchantsPointMovements",
        },
    },
    [PermissionModules.POINT_ANALYTICS]: {
        moduleName: "PointAnalytics",
        moduleDescription: "Point Analytics management module",
        actions: {
            ViewPointsSummaryByTypeCount: "ViewPointsSummaryByTypeCount",
            ViewPointsOverviewCount: "ViewPointsOverviewCount",
            ViewPointsOverviewSeries: "ViewPointsOverviewSeries",
            ExportPointsOverviewSeries: "ExportPointsOverviewSeries",
        },
    },
    [PermissionModules.REWARD_ANALYTICS]: {
        moduleName: "RewardAnalytics",
        moduleDescription: "Reward Analytics management module",
        actions: {
            ViewTopRewards: "ViewTopRewards",
            ViewRedeemedRewardsCount: "ViewRedeemedRewardsCount",
            ExportRedeemedRewardsCount: "ExportRedeemedRewardsCount",
            ViewRedeemedRewardsSeries: "ViewRedeemedRewardsSeries",
            ExportRedeemedRewardsSeries: "ExportRedeemedRewardsSeries",
        },
    },
    [PermissionModules.TIER_ANALYTICS]: {
        moduleName: "TierAnalytics",
        moduleDescription: "Tier Analytics management module",
        actions: {
            ViewAffinityGroupPointCounts: "ViewAffinityGroupPointCounts",
            ExportAffinityGroupPointCounts: "ExportAffinityGroupPointCounts",
            ViewTierPointCounts: "ViewTierPointCounts",
            ExportTierPointCounts: "ExportTierPointCounts",
            ViewTierDistribution: "ViewTierDistribution",
        },
    },
    [PermissionModules.RULE]: {
        moduleName: "Rules",
        moduleDescription: "Rules management module",
        actions: {
            ListRules: "ListRules",
            GetRule: "GetRule",
            CreateRule: "CreateRule",
            DeleteRule: "DeleteRule",
            UpdateRule: "UpdateRule",
        },
    },
    [PermissionModules.RULE_TYPE]: {
        moduleName: "RuleTypes",
        moduleDescription: "Rule Types management module",
        actions: {
            ListRuleTypes: "ListRuleTypes",
            GetRuleType: "GetRuleType",
            CreateRuleType: "CreateRuleType",
            DeleteRuleType: "DeleteRuleType",
            UpdateRuleType: "UpdateRuleType",
        },
    },
    [PermissionModules.INCIDENT]: {
        moduleName: "Incidents",
        moduleDescription: "Incidents management module",
        actions: {
            ListIncidents: "ListIncidents",
            GetIncident: "GetIncident",
            UpdateIncident: "UpdateIncident",
            ExportIncidents: "ExportIncidents",
            ExportIncident: "ExportIncident",
        },
    },
    [PermissionModules.CHARITIES]: {
        moduleName: "Charity",
        moduleDescription: "Charities modules",
        actions: {
            ListCharities: "ListCharities",
            GetCharity: "GetCharity",
            CreateCharity: "CreateCharity",
            UpdateCharity: "UpdateCharity",
            DeleteCharity: "DeleteCharity",
            ExportCharity: "ExportCharity",
            EraseCharity: "EraseCharity",
        },
    },
    [PermissionModules.JOBS]: {
        moduleName: "Jobs",
        moduleDescription: "Jobs modules",
        actions: {
            CreateJob: "CreateJob",
            UpdateJob: "UpdateJob",
            ListJobs: "ListJobs",
            GetJob: "GetJob",
            DeleteJob: "DeleteJob",
        },
    },

    [PermissionModules.JOB_TYPES]: {
        moduleName: "JobTypes",
        moduleDescription: "Job types modules",
        actions: {
            CreateJobType: "CreateJobType",
            UpdateJobType: "UpdateJobType",
            ListJobTypes: "ListJobTypes",
            GetJobType: "GetJobType",
            DeleteJobType: "DeleteJobType",
        },
    },
    [PermissionModules.AUDIT_LOG]: {
        moduleName: "AuditLog",
        moduleDescription: "Audit log modules",
        actions: {
            ListAuditLogs: "ListAuditLogs",
        },
    },
    [PermissionModules.KLIP]: {
        moduleName: "Klip",
        moduleDescription: "Klip modules",
        actions: {
            RedeemPoints: "RedeemPoints",
            RedeemPointsWithoutOtp: "RedeemPointsWithoutOtp",
            CollectPointsBill: "CollectPointsBill",
            CollectPointsAmount: "CollectPointsAmount",
            ListRewards: "ListRewards",
            RedeemReward: "RedeemReward",
            ListRewardRedemptionLogs: "ListRewardRedemptionLogs",
            ListMembers: "ListMembers",
            GetMember: "GetMember",
            ClaimReward: "ClaimReward",
            AdjustPoints: "AdjustPoints",
        },
    },
    [PermissionModules.JOB_EXECUTIONS]: {
        moduleName: "JobExecutions",
        moduleDescription: "Job executions modules",
        actions: {
            ListJobExecutions: "ListJobExecutions",
            GetJobExecution: "GetJobExecution",
        },
    },
    [PermissionModules.REGION]: {
        moduleName: "Region",
        moduleDescription: "Region executions modules",
        actions: {
            CreateRegion: "CreateRegion",
            UpdateRegion: "UpdateRegion",
            ListRegions: "ListRegions",
            DeleteRegion: "DeleteRegion",
        },
    },
    [PermissionModules.CAMPAIGNS]: {
        moduleName: "Campaigns",
        moduleDescription: "Campaigns management module",
        actions: {
            ListCampaigns: "ListCampaigns",
            ListMessageLogs: "ListMessageLogs",
            GetCampaign: "GetCampaign",
            CreateCampaign: "CreateCampaign",
            UpdateCampaign: "UpdateCampaign",
            DeleteCampaign: "DeleteCampaign",
            GetCampaignReport: "GetCampaignReport",
        },
    },
};

export default PermissionModulesDetails;

export { PermissionModules as AccessPermissionModuleNames };
