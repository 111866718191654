const TransactionTypes = {
    COLLECTION: "COLLECTION",
    REDEMPTION: "REDEMPTION",
    ADJUSTMENT: "ADJUSTMENT",
};




const TransactionTypeColorCode = {
    [TransactionTypes.COLLECTION]: "success",
    [TransactionTypes.REDEMPTION]: "orange",
    [TransactionTypes.ADJUSTMENT]: "purple",
};

export { TransactionTypes, TransactionTypeColorCode};
