import React, { useEffect } from "react";
import "@shoutout-labs/shoutout-themes-enterprise/es/themes/enterprise-loyalty/bootstrap.min.css";
import { Switch } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { datadogRum } from "@datadog/browser-rum";
import {
    CreateCampaignContextProvider,
    DataContextProvider,
    UserContextProvider,
} from "Contexts";
import { KeycloakContextProvider } from "./Contexts/keycloakAuthServiceContext";
import Home from "./Components/home/Home";
import LoginPage from "./Components/login/Login";
import AuthRoute from "./AuthRoute";
import CustomRoute from "./CustomRoute";
import AppContext from "AppContext";

import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

const App = () => {
    useEffect(() => {
        if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === "development" ||
            process.env.NODE_ENV === "test"
        ) {
        } else {
            datadogRum.init({
                sampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: "mask-user-input",
                ...AppContext.dataDogConfig,
            });
            datadogRum.startSessionReplayRecording();

            return () => datadogRum.stopSessionReplayRecording();
        }
    }, []);
    return (
        <div className="App h-100">
            <ToastContainer
                position="top-center"
                transition={Slide}
                theme="colored"
                pauseOnFocusLoss={false}
            />
            <KeycloakContextProvider>
                <UserContextProvider>
                    <DataContextProvider>
                        <CreateCampaignContextProvider>
                            <Switch>
                                <CustomRoute
                                    name="LoginPage"
                                    exact
                                    path="/login"
                                    component={LoginPage}
                                />
                                <AuthRoute path="/" component={Home} />
                            </Switch>
                        </CreateCampaignContextProvider>
                    </DataContextProvider>
                </UserContextProvider>
            </KeycloakContextProvider>
        </div>
    );
};

export default App;
