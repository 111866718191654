import React from "react";
import { AlertBoxConfirm } from '@shoutout-labs/shoutout-themes-enterprise';

const ConfirmBox = (props) => {
    
        const { show, onHide, confirmCallback, title, message, disableActions = false, variant } = props;

        return (
            <AlertBoxConfirm show={show} handleClose={onHide} handleOk={confirmCallback} body={message} title={title} variant={variant} btnDisable={disableActions} />
        );
    
};

export default ConfirmBox;