import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "./Contexts/userContext";
import { LoadingComponent } from "./Components/utils/UtilComponents";
import SystemInitFailedMessage from "Components/system/initFailed";

const AuthRoute = ({ component: Component, routeName, ...rest }) => {
  const { 
    isAuth, 
    isRunningSystemInit, 
    systemInitFailMessage, 
    initialized, 
    login 
  } = useContext(UserContext);

  useEffect(() => {
    if(!isAuth && initialized) {
      login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, initialized]);

  return (
    <Route
      {...rest}
      render={props =>
        (!isAuth && initialized) || isRunningSystemInit ? (
          <LoadingComponent />
        ) : systemInitFailMessage ? (
          <SystemInitFailedMessage message={systemInitFailMessage} />
        ) : (
          <Component {...props} routeName={routeName} />
        )
      }
    />
  );
};

export default AuthRoute;
