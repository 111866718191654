import {
    fetchGet,
    fetchPut,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
    preProcessBodyData,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const createPoints = (pointObj) => {
    return fetchPost(
        BASE_URL + "pointrules",
        pointObj,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT_RULES,
            AccessPermissionModules[AccessPermissionModuleNames.POINT_RULES]
                .actions.CreatePointRule
        )
    );
};

const getPoints = (queryObj) => {
    // Query params - limit, skip, merchantId, regionId
    return fetchGet(
        `${BASE_URL}pointrules?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT_RULES,
            AccessPermissionModules[AccessPermissionModuleNames.POINT_RULES]
                .actions.ListPointRules
        )
    );
};

const editPoints = (id, payload) => {
    return fetchPut(
        `${BASE_URL}pointrules/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT_RULES,
            AccessPermissionModules[AccessPermissionModuleNames.POINT_RULES]
                .actions.UpdatePointRule
        )
    );
};

const deletePoints = (id) => {
    return fetchDelete(
        BASE_URL + "pointrules/" + id,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT_RULES,
            AccessPermissionModules[AccessPermissionModuleNames.POINT_RULES]
                .actions.DeletePointRule
        )
    );
};

const adjustPoints = (payload) => {
    return fetchPost(
        BASE_URL + "points/adjust",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .AdjustPoints
        )
    );
};

const redeemPoints = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeem",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .RedeemPoints
        )
    );
};

const redeemPointsWithOtpRequest = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeemwithotprequest",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .RedeemPoints
        )
    );
};

const redeemPointsWithOtp = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeemwithotp",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .RedeemPoints
        )
    );
};

const collectPoints = (payload) => {
    return fetchPost(
        `${BASE_URL}points/collect/points`,
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .CollectPointsAmount
        )
    );
};

const collectPointsManualBill = (payload) => {
    return fetchPost(
        `${BASE_URL}points/collect/bill`,
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .CollectPointsBill
        )
    );
};

const transferPoints = (payload) => {
    return fetchPost(
        BASE_URL + "points/transfer",
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .TransferPoints
        )
    );
};

const donatePoints = (payload) => {
    return fetchPost(
        BASE_URL + "points/donate",
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.POINT,
            AccessPermissionModules[AccessPermissionModuleNames.POINT].actions
                .AdjustPoints
        )
    );
};

export {
    createPoints,
    getPoints,
    editPoints,
    deletePoints,
    adjustPoints,
    collectPoints,
    collectPointsManualBill,
    redeemPoints,
    redeemPointsWithOtpRequest,
    redeemPointsWithOtp,
    donatePoints,
    transferPoints,
};
