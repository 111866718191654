import React, { useContext } from "react";
import {
  Modal,
  Button,
  ShIcon,
} from "@shoutout-labs/shoutout-themes-enterprise";
import { ShInformationCircleOutline } from "@shoutout-labs/shoutout-themes-enterprise/lib/ShoutOUTIcon";
import { UserContext } from "Contexts";
const SystemInitFailedMessage = ({ message }) => {
  const { logout } = useContext(UserContext);
  return (
    <Modal.Dialog aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className="my-3 text-danger">
          <ShIcon icon={ShInformationCircleOutline} size="4x" />
        </div>
        {message}

        <div className="my-4">
          <Button onClick={logout} variant="secondary" size="sm">
            Logout
          </Button>
        </div>
      </Modal.Body>
    </Modal.Dialog>
  );
};
export default SystemInitFailedMessage;
