import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModuleNames, AccessPermissionModules } from "../Data";
import { getPermissionPath } from "../Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/subtransactiontypes`;

const getSubTransactionTypes = ({
    limit = 100,
    skip = 0,
    searchKey,
    transactionType,
    createdBy,
    withSystemSubTransactionTypes,
}) => {
    return fetchGet(
        `${BASE_URL}?${jsonToQueryParam({
            limit,
            skip,
            searchKey,
            transactionType,
            createdBy,
            withSystemSubTransactionTypes,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SUB_TRANSACTION_TYPE,
            AccessPermissionModules[
                AccessPermissionModuleNames.SUB_TRANSACTION_TYPE
            ].actions.ListSubTransactionTypes
        )
    );
};

const getAllSubTransactionTypes = async (args) => {
    const limit = 500;
    let totalCount = 0,
        subTransactionTypes = [];
    try {
        do {
            const subTransactionTypesResponse = await getSubTransactionTypes({
                limit,
                skip: subTransactionTypes.length,
                ...args,
            });
            totalCount = subTransactionTypesResponse.total;
            subTransactionTypes = [
                ...subTransactionTypes,
                ...subTransactionTypesResponse.items,
            ];
        } while (subTransactionTypes.length < totalCount);
        return subTransactionTypes;
    } catch (e) {
        return Promise.reject(e);
    }
};
export { getSubTransactionTypes, getAllSubTransactionTypes };
