const DaysOfWeek = [
    {
        name : "Sunday",
        value : "SUNDAY"
    },
    {
        name : "Monday",
        value : "MONDAY"
    },
    {
        name : "Tuesday",
        value : "TUESDAY"
    },
    {
        name : "Wednesday",
        value : "WEDNESDAY"
    },
    {
        name : "Thursday",
        value : "THURSDAY"
    },
    {
        name : "Friday",
        value : "FRIDAY"
    },
    {
        name : "Saturday",
        value : "SATURDAY"
    }
]

export default DaysOfWeek;