import {
  fetchGet,
  fetchPost,
  fetchPut,
  jsonToQueryParam,
  fetchDelete,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { getPermissionPath } from "Utils";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getLocationsForMerchant = ({ limit = 100, skip = 0, ...props }) => {
  return fetchGet(
    `${BASE_URL}locations?${jsonToQueryParam({ limit, skip, ...props })}`,
    true,
        getPermissionPath(
            AccessPermissionModuleNames.LOCATION,
            AccessPermissionModules[AccessPermissionModuleNames.LOCATION].actions
                .ListLocations
        )
  );
};
const archiveMerchantLocation = (id) => {
  return fetchDelete(
    `${BASE_URL}locations/${id}`,
    null,
    null,
    true,
        getPermissionPath(
            AccessPermissionModuleNames.LOCATION,
            AccessPermissionModules[AccessPermissionModuleNames.LOCATION].actions
                .DeleteLocation
        )
    );
};
const editMerchantLocation = (locationId, payload) => {
  return fetchPut(
    `${BASE_URL}locations/${locationId}`, payload,
    true,
        getPermissionPath(
            AccessPermissionModuleNames.LOCATION,
            AccessPermissionModules[AccessPermissionModuleNames.LOCATION].actions
                .UpdateLocation
        )
  );
};
const addLocation = (payload) => {
  return fetchPost(
    `${BASE_URL}locations`, payload,
    true,
        getPermissionPath(
            AccessPermissionModuleNames.LOCATION,
            AccessPermissionModules[AccessPermissionModuleNames.LOCATION].actions
                .CreateLocation
        )
  );
};

//retrieve all the locations by applying limit,skip automatically
const getAllMerchantLocations = (args) => {
  return new Promise(async (resolve, reject) => {
    const limit = 500;
    let totalCount = 0,
      merchantLocations = [];
    try {
      do {
        const merchantLocationsResponse = await getLocationsForMerchant({
          limit,
          skip: merchantLocations.length,
          ...args,
        });
        totalCount = merchantLocationsResponse.total;
        merchantLocations = [
          ...merchantLocations,
          ...merchantLocationsResponse.items,
        ];
      } while (merchantLocations.length < totalCount);
      resolve(merchantLocations);
    } catch (e) {
      reject(e);
    }
  });
};
export {
  getLocationsForMerchant,
  archiveMerchantLocation,
  editMerchantLocation,
  addLocation,
  getAllMerchantLocations,
};
