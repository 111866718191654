import moment from 'moment';

const AnalyticsDatePeriods = {
    "Today": {
      toDate: moment().format("YYYY-MM-DD"),
      fromDate: moment().startOf('day').format('YYYY-MM-DD'),
      selectedTimePeriod : 'yesterday'
    },
    "Yesterday": {
      toDate: moment().subtract(1, 'day').format("YYYY-MM-DD"),
      fromDate: moment().subtract(2, 'day').format("YYYY-MM-DD"),
      selectedTimePeriod : 'day before yesterday'
    },
    "Last 7 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(7, 'day').format("YYYY-MM-DD"),
        selectedTimePeriod : 'prev. 7 days'
    },
    "Last 28 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(28, 'day').format("YYYY-MM-DD"),
        selectedTimePeriod : 'prev. 28 days'
    },
    "Last 90 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(90, 'day').format("YYYY-MM-DD"),
        selectedTimePeriod : 'prev. 90 days'
    },
    "Last 365 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(365, 'day').format("YYYY-MM-DD"),
        selectedTimePeriod : 'prev. 365 days'
    }
  };

  export default AnalyticsDatePeriods;