const getNonSystemAttributes = ({
  created_by,
  _delete_protected,
  _email_valid,
  _mobile_number_valid,
  owner_id,
  historyEvents,
  identifications,
  partnerApi,
  ...rest
}) => ({...rest});


export {getNonSystemAttributes};
