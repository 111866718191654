import {
    OverlayTrigger,
    Tooltip,
} from "@shoutout-labs/shoutout-themes-enterprise";
import Constants from "../Constants";
import { abbreviateNumber } from "./NumberUtils";
import { truncateLongString } from "./StringUtils";

const getSmsLength = (message) => {
    message = message.replace(
        /<<(.*?)>>/g,
        Constants.SHORT_URL_DOMAIN + "/xxxxxx"
    );
    return message.length;
};

const getDefaultValuesOfFilters = (
    { filterInput = "", filterKey = "" },
    inputKeys = []
) => {
    switch (filterInput) {
        case "select": {
            return { defaultValue: [] };
        }
        case "date-range": {
            const dateRangeInputMetadata =
                inputKeys.find((iK) => iK.key === "date-range").values || {};
            const dateRangeKeys = dateRangeInputMetadata[filterKey] || {};

            return {
                ...dateRangeKeys,
                defaultFromDate: "",
                defaultToDate: "",
            };
        }
        default: {
            return { defaultValue: "" };
        }
    }
};

const getQueryFilters = (filters = []) =>
    filters.reduce((result, filter) => {
        result = { ...result, ...filter };
        return result;
    }, {});

const getTruncatedStringWithTooltip = ({
    value = "",
    valueMaxLength = 30,
    placement = "bottom",
    customUnknownValue = null,
}) =>
    value ? (
        <>
            {value.length > valueMaxLength ? (
                <OverlayTrigger
                    placement={placement}
                    overlay={<Tooltip id="tooltip-contact">{value}</Tooltip>}
                >
                    <div>{truncateLongString(value, valueMaxLength)}</div>
                </OverlayTrigger>
            ) : (
                value
            )}
        </>
    ) : (
        customUnknownValue || "~ unknown"
    );

const extractCustomErrorCodeFromErrorMessage = (errMsg) => {
    if (!errMsg) return null;

    const errorCodeWithText = errMsg.substring(
        errMsg.indexOf("[") + 1,
        errMsg.lastIndexOf("]")
    );

    if (!errorCodeWithText) return null;

    const code = errorCodeWithText.split(":")[1] || null;

    return code;
};

const getAbbreviateNumberWithOverlay = ({
    number = 0,
    decimalPoints = 1,
    placement = "bottom",
    sumValueClassName = "",
}) => (
    <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id="tooltip-show-full-number">{number}</Tooltip>}
    >
        <span className="d-inline-blck">
            <div className={sumValueClassName}>
                {abbreviateNumber(number || 0, decimalPoints)}
            </div>
        </span>
    </OverlayTrigger>
);

const getValueWithOverlay = ({
    valuePlaceholder = null,
    fullValue = "~unknown",
    fullValueTextClassName = "",
    placement = "bottom",
}) => (
    <OverlayTrigger
        placement={placement}
        overlay={
            <Tooltip id="tooltip-show-full-value">
                <div className={fullValueTextClassName}>{fullValue}</div>
            </Tooltip>
        }
    >
        {valuePlaceholder || "~unknown"}
    </OverlayTrigger>
);

export {
    getSmsLength,
    getDefaultValuesOfFilters,
    getQueryFilters,
    getTruncatedStringWithTooltip,
    extractCustomErrorCodeFromErrorMessage,
    getAbbreviateNumberWithOverlay,
    getValueWithOverlay,
};
