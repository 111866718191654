import {
    faCalendar,
    faHandHoldingUsd,
    faUser,
    faCoins,
    faCheckCircle,
    faTimesCircle,
    faPower,
    faCreditCard,
} from "FaICIconMap";
import { toTitleCase } from "Utils";

const CALMilesProcessStatus = {
    COMPLETE: "COMPLETE",
    FAIL: "FAIL",
};

const CALMilesJobStatus = {
    ALL: "ALL",
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
};

const PartnerRewardPendingActionsStatus = {
    ALL: "ALL",
    REQUESTED: "REQUESTED",
    PROCESSING: "PROCESSING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    REFUNDED: "REFUNDED",
};

const CALMilesJobGroups = {
    INDIVIDUAL: "INDIVIDUAL",
    BATCHES: "BATCHES",
};

const DefaultColumnsPerCalMilesGroup = {
    INDIVIDUAL: [
        {
            name: "transaction_id",
            headerStyle: { width: "15%" },
            icon: faHandHoldingUsd,
            sort: false,
        },
        {
            name: "customer_name",
            headerStyle: { width: "15%" },
            icon: faUser,
            sort: false,
        },
        { name: "points", icon: faCoins, sort: false },
        { name: "bundle_value", icon: faCoins, sort: false },
        {
            name: "date",
            headerStyle: { width: "15%" },
            icon: faCalendar,
            sort: false,
        },
        {
            name: "status",
            icon: faPower,
            sort: false,
            headerStyle: { width: "10%" },
        },
        { name: "_id", hidden: true, icon: null, sort: false },
        { name: "fullObj", hidden: true, icon: null, sort: false },
        { name: "", icon: null, sort: false, headerStyle: { width: "15%" } },
    ],
    BATCHES: [
        { name: "batch_id", icon: faCreditCard, sort: false },
        { name: "total_transactions", icon: faHandHoldingUsd, sort: false },
        { name: "successful_transactions", icon: faCheckCircle, sort: false },
        { name: "failed_transactions", icon: faTimesCircle, sort: false },
        { name: "date", icon: faCalendar, sort: false },
        { name: "status", icon: faPower, sort: false },
        { name: "_id", hidden: true, icon: null, sort: false },
    ],
};

const CalMilesDefaultColumsObject = {
    ALL: DefaultColumnsPerCalMilesGroup.INDIVIDUAL,
    PENDING: DefaultColumnsPerCalMilesGroup.INDIVIDUAL,
    PROCESSING: {
        INDIVIDUAL: DefaultColumnsPerCalMilesGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerCalMilesGroup.BATCHES,
    },
    COMPLETED: {
        INDIVIDUAL: DefaultColumnsPerCalMilesGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerCalMilesGroup.BATCHES,
    },
    FAILED: {
        INDIVIDUAL: DefaultColumnsPerCalMilesGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerCalMilesGroup.BATCHES,
    },
};

const CALMilesIndividualFilter = [
    {
        value: "Batch Id",
        label: "Batch Id",
    },
    {
        value: "Date",
        label: "Date",
    },
];

const BatchesWithRefundOnlyAction = [
    CALMilesJobStatus.COMPLETED,
    CALMilesJobStatus.FAILED,
];

const PartnerRewardRefSearchFields = {
    PARTNER_REF_NUMBER: "PARTNER_REF_NUMBER",
    PARTNER_REF_NAME: "PARTNER_REF_NAME",
};

const getPartnerRewardSearchFields = (partnerRewardName) => [
    {
        label: `${toTitleCase(partnerRewardName || "Partner")} Number`,
        value: PartnerRewardRefSearchFields.PARTNER_REF_NUMBER,
    },
    {
        label: "Customer Name",
        value: PartnerRewardRefSearchFields.PARTNER_REF_NAME,
    },
];

export {
    CALMilesIndividualFilter,
    CalMilesDefaultColumsObject,
    CALMilesJobGroups,
    CALMilesJobStatus,
    CALMilesProcessStatus,
    PartnerRewardPendingActionsStatus,
    BatchesWithRefundOnlyAction,
    PartnerRewardRefSearchFields,
    getPartnerRewardSearchFields,
};
