import ShoutOUTLogo from "./assets/images/shoutout_logo.png";
import DilmahLogo from "./assets/images/dilmah/dilmah.svg";
// const DefaultContext = {
//   logo: ShoutOUTLogo,
//   keycloakConfig: process.env.REACT_APP_KEYCLOAK_CONFIG, //`${process.env.PUBLIC_URL}/assets/config/keycloak.json`,
//   baseUrl: "https://enterprise.api.topnotch.club/api/",
// };
const DimahContext = {
  logo: DilmahLogo,
  keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/dilmah/keycloak.json`,
  baseUrl: "https://loyaltyapi-uat.dilmaht-lounge.com/api/",
};

const DefaultContext = {
  logo: ShoutOUTLogo,
  //   keycloakConfig: {
  //     "realm": process.env.REACT_APP_KEYCLOAK_REALM,
  //     "auth-server-url": process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  //     // "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED||true,
  //     "clientId": process.env.REACT_APP_KEYCLOAK_RESOURCE,
  //     "resource": process.env.REACT_APP_KEYCLOAK_RESOURCE,
  //     // "public-client": true,
  //     // "confidential-port": 0,
  //   },
  keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/${process.env.REACT_APP_KEYCLOAK_FILE_NAME}`,
  baseUrl:
    process.env.REACT_APP_API_BASE_URL ||
    "https://api.loyaltybeta.cxforge.com/api/",
 
  dataDogConfig: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION||"1.0.0"
  },
};

let context;

switch (process.env.REACT_APP_BUILD_VARIANT) {
  case "dilmah": {
    context = DimahContext;
    break;
  }
  //   case "dev": {
  //     context = DevContext;
  //     break;
  //   }
  default: {
    context = DefaultContext;
    break;
  }
}

export default context;
