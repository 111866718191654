import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}coreservice/`;


const getActivities = (activityFilters) => {
    return fetchGet(
        `${BASE_URL}activities?${jsonToQueryParam(activityFilters)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ACTIVITY,
            AccessPermissionModules[AccessPermissionModuleNames.ACTIVITY]
                .actions.ListActivities
        )
    );
};

const getEvents = (queryObj) => {
    return fetchGet(
        `${BASE_URL}events?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.EVENT,
            AccessPermissionModules[AccessPermissionModuleNames.EVENT].actions
                .ListEvents
        )
    );
};

export { getActivities, getEvents };
