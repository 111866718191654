import numeral from "numeral";

numeral.nullFormat("N/A");

const percentageConverter = (total, amount) => {
    const percentageAmount = ((Number(amount) / Number(total)) * 100).toFixed(
        2
    );
    return percentageAmount;
};

const convertToLocaleString = (number) => {
    if (number && typeof number === "number") {
        return number.toLocaleString();
    } else {
        return 0;
    }
};

const convertNumberFormat = (amount) => {
    return numeral(amount).format("0,0");
};

const roundOffToTwoDecimals = (value) =>
    Math.round((value + Number.EPSILON) * 100) / 100;

const abbreviateNumber = (number, decPlaces) => {
    // * 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);
    let isNegativeNumber = number < 0;
    let absoluteNumber = !isNaN(number) ? Math.abs(number) : number;

    // * Enumerate number abbreviations
    const abbreviationsList = ["K", "M", "B", "T"];

    // * Go through the array backwards, so we do the largest first
    for (
        let abbreviation = abbreviationsList.length - 1;
        abbreviation >= 0;
        abbreviation--
    ) {
        // * Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (abbreviation + 1) * 3);

        // * If the number is bigger or equal do the abbreviation
        if (size <= absoluteNumber) {
            // * Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // * This gives us nice rounding to a particular decimal place.
            absoluteNumber =
                Math.round((absoluteNumber * decPlaces) / size) / decPlaces;

            // * Handle special case where we round up to the next abbreviation
            if (
                absoluteNumber === 1000 &&
                abbreviation < abbreviationsList.length - 1
            ) {
                absoluteNumber = 1;
                abbreviation++;
            }

            // * Add the letter for the abbreviation
            absoluteNumber += abbreviationsList[abbreviation];
            break;
        }
    }

    return isNegativeNumber ? `-${absoluteNumber}` : absoluteNumber;
};

// * The below method will generate a random number with the given length (numberLength).
// ? For reference: https://stackoverflow.com/a/65077098
const generateARandomNumber = (numberLength = 14) => {
    return Number(
        String(Math.ceil(Math.random() * 10 ** numberLength)).padEnd(
            numberLength,
            "0"
        )
    );
};

export {
    percentageConverter,
    convertNumberFormat,
    convertToLocaleString,
    roundOffToTwoDecimals,
    abbreviateNumber,
    generateARandomNumber,
};
