import { toast } from "react-toastify";

const areArraysEquals = (arr1, arr2, isObjectArr = false) =>
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((val, index) =>
        isObjectArr
            ? JSON.stringify(val) === JSON.stringify(arr2[index])
            : val === arr2[index]
    );

// * To remove duplicates from array of objects.
const removeDuplicatesFromArrayOfObjects = (arrayOfObjects = []) =>
    Array.from(new Set(arrayOfObjects.map(JSON.stringify))).map(JSON.parse);

// * To remove duplicated keys from an array of objects.
// ? Reference: https://stackoverflow.com/a/45440277.
const removeDuplicatedKeysFromArrayOfObjects = (
    arrayOfObjects = [],
    keyToConsider = ""
) => {
    try {
        if (!keyToConsider) throw new Error(`'keyToConsider' cannot be empty!`);

        const uniqueArrOfObjs = arrayOfObjects.reduce((result, obj) => {
            if (!result?.some((el) => el[keyToConsider] === obj[keyToConsider]))
                result.push(obj);

            return result;
        }, []);

        return uniqueArrOfObjs;
    } catch (err) {
        console.error(err);
        toast.error(
            <div>
                Failed to remove duplicated keys of array!
                <br />
                {err.message
                    ? `Error: ${err.message}`
                    : "Please try again later."}
            </div>
        );

        return arrayOfObjects;
    }
};

export {
    areArraysEquals,
    removeDuplicatesFromArrayOfObjects,
    removeDuplicatedKeysFromArrayOfObjects,
};
