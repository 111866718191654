import Constants from "../Constants";
import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/analytics/`;

const getTopListAnalytics = (tableURL, queryObjectTopRewards) => {
    return fetchGet(
        `${BASE_URL}${tableURL}?${jsonToQueryParam(queryObjectTopRewards)}`
    );
};

const exportAnalyticsReport = (filePath, exportQueryObject) => {
    return fetchGet(
        `${BASE_URL}${filePath}?${jsonToQueryParam(exportQueryObject)}`
    );
};

const getMemberRegistrationAnalyticsSeries = (queryObjectRegistration) => {
    return fetchGet(
        `${BASE_URL}members/registration/series?${jsonToQueryParam(
            queryObjectRegistration
        )}`
    );
};

const getMemberRegistrationAnalyticsCount = (queryObjectRegistration) => {
    return fetchGet(
        `${BASE_URL}members/registration/counts?${jsonToQueryParam(
            queryObjectRegistration
        )}`
    );
};

const getMemberAffinityGroupCount = (queryObjectAffinityGroup) => {
    return fetchGet(
        `${BASE_URL}members/affinitygroup/counts?${jsonToQueryParam(
            queryObjectAffinityGroup
        )}`
    );
};

const getMemberAffinityGroupSeries = (queryObjectAffinityGroup) => {
    return fetchGet(
        `${BASE_URL}members/affinitygroup/series?${jsonToQueryParam(
            queryObjectAffinityGroup
        )}`
    );
};

const getNewReturnMemberCount = (queryObjectReturnMember) => {
    return fetchGet(
        `${BASE_URL}members/new-return/counts?${jsonToQueryParam(
            queryObjectReturnMember
        )}`
    );
};

const getNewReturnMemberSeries = (queryObjectReturnMember) => {
    return fetchGet(
        `${BASE_URL}members/new-return/series?${jsonToQueryParam(
            queryObjectReturnMember
        )}`
    );
};

const getMemberTiersCount = (queryObjectTiers) => {
    return fetchGet(
        `${BASE_URL}members/tiers/counts?${jsonToQueryParam(queryObjectTiers)}`
    );
};

const getMemberTiersSeries = (queryObjectTiers) => {
    return fetchGet(
        `${BASE_URL}members/tiers/series?${jsonToQueryParam(queryObjectTiers)}`
    );
};

const getMemberPointsBreakdownBySubTransactions = (queryObject) => {
    return fetchGet(
        `${BASE_URL}members/points-subtransactiontypes/counts?${jsonToQueryParam(
            queryObject
        )}`
    );
};

/* Redemptions Analytics */

const getTopRewardAnalytics = (queryObjectTopRewards) => {
    return fetchGet(
        `${BASE_URL}rewards/top?${jsonToQueryParam(queryObjectTopRewards)}`
    );
};

const getRedemptionCount = (queryObjectRedmptionCount) => {
    return fetchGet(
        `${BASE_URL}rewards/redeemedrewards/count?${jsonToQueryParam(
            queryObjectRedmptionCount
        )}`
    );
};

const getRedemptionSeries = (queryObjectRedemptionSeries) => {
    return fetchGet(
        `${BASE_URL}rewards/redeemedrewards/series?${jsonToQueryParam(
            queryObjectRedemptionSeries
        )}`
    );
};

/* Cards Analytics */

const getCardsAnalytics = (queryObject) => {
    return fetchGet(
        `${BASE_URL}cards/summary?${jsonToQueryParam(queryObject)}`
    );
};

const getCardsProcessingStatus = (queryObject) => {
    return fetchGet(
        `${BASE_URL}cards/processingstatussummary?${jsonToQueryParam(
            queryObject
        )}`
    );
};

const getCardsActivationsummary = (queryObject) => {
    return fetchGet(
        `${BASE_URL}cards/activationsummary?${jsonToQueryParam(queryObject)}`
    );
};
/* Merchant Analytics */

const MERCHANTS_URL = `${BASE_URL}merchants/`;

// TODO: Deprecated. Remove later.
// const getMerchantAsSeries = ({ transactionType, queryObj }) => {
//     return fetchGet(
//         `${MERCHANTS_URL}transactions/${transactionType}/series?${jsonToQueryParam(
//             queryObj
//         )}`
//     );
// };

// TODO: Deprecated. Remove later.
// const getMerchantAsCount = ({ transactionType, queryObj }) => {
//     return fetchGet(
//         `${MERCHANTS_URL}transactions/${transactionType}/counts?${jsonToQueryParam(
//             queryObj
//         )}`
//     );
// };

const getMerchantAsSeriesV2 = ({ transactionType, queryObj }) => {
    return fetchGet(
        `${MERCHANTS_URL}transactions/series/${transactionType}?${jsonToQueryParam(
            queryObj
        )}`
    );
};

const getMerchantAsCountV2 = ({ transactionType, queryObj }) => {
    return fetchGet(
        `${MERCHANTS_URL}transactions/counts/${transactionType}?${jsonToQueryParam(
            queryObj
        )}`
    );
};

/* Collections */
const MERCHANTS_COLLECTIONS_URL = `${MERCHANTS_URL}transactions/collections/`;

const getMerchantCollectionAsCount = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_COLLECTIONS_URL}counts?${jsonToQueryParam(queryObj)}`
    );
};

const getMerchantCollectionAsSeries = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_COLLECTIONS_URL}series?${jsonToQueryParam(queryObj)}`
    );
};

/* Redemptions */
const MERCHANTS_REDEMPTIONS_URL = `${MERCHANTS_URL}transactions/redemptions/`;

const getMerchantRedemptionAsCount = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_REDEMPTIONS_URL}counts?${jsonToQueryParam(queryObj)}`
    );
};

const getMerchantRedemptionAsSeries = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_REDEMPTIONS_URL}series?${jsonToQueryParam(queryObj)}`
    );
};

/* Adjustments */
const MERCHANTS_ADJUSTMENT_URL = `${MERCHANTS_URL}transactions/adjustments/`;

const getMerchantAdjustmentAsCount = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_ADJUSTMENT_URL}counts?${jsonToQueryParam(queryObj)}`
    );
};

const getMerchantAdjustmentAsSeries = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_ADJUSTMENT_URL}series?${jsonToQueryParam(queryObj)}`
    );
};

const getMerchantPointmovementsAnalytics = (queryObj) => {
    return fetchGet(
        `${MERCHANTS_URL}pointmovements?${jsonToQueryParam(queryObj)}`
    );
};

/*  Points Analytics - V2 */

// * Point Cards Analytics view
const getPointsAllTimeCard = (queryObjectCard) => {
    return fetchGet(
        `${BASE_URL}points/summarybytype/counts?${jsonToQueryParam(
            queryObjectCard
        )}`
    );
};

const getPointsOverviewCounts = (queryObject) => {
    return fetchGet(
        `${BASE_URL}points/overview/counts?${jsonToQueryParam(queryObject)}`
    );
};

const getPointsOverviewSeries = (queryObject) => {
    return fetchGet(
        `${BASE_URL}points/overview/series?${jsonToQueryParam(queryObject)}`
    );
};

const getPointsSummary = (queryObject) => {
    return fetchGet(
        `${BASE_URL}points/overview/subtype/series?${jsonToQueryParam(
            queryObject
        )}`
    );
};

const exportPointsOverviewSeries = (queryObject) => {
    return fetchGet(
        `${BASE_URL}points/overview/series/export?${jsonToQueryParam(
            queryObject
        )}`
    );
};

const exportPointsSummarySeries = (queryObject) => {
    return fetchGet(
        `${BASE_URL}points/overview/subtype/series/export?${jsonToQueryParam(
            queryObject
        )}`
    );
};

/*  Points Analytics - V2 */

export {
    getMemberRegistrationAnalyticsSeries,
    getMemberRegistrationAnalyticsCount,
    getMemberAffinityGroupCount,
    getMemberAffinityGroupSeries,
    getMemberTiersCount,
    getMemberTiersSeries,
    getRedemptionCount,
    getRedemptionSeries,
    getTopRewardAnalytics,
    getCardsAnalytics,
    getMerchantPointmovementsAnalytics,
    getMerchantCollectionAsCount,
    getMerchantCollectionAsSeries,
    getMerchantRedemptionAsCount,
    getMerchantRedemptionAsSeries,
    getMerchantAdjustmentAsCount,
    getMerchantAdjustmentAsSeries,
    // getMerchantAsSeries, // TODO: Deprecated. Remove later.
    // getMerchantAsCount, // TODO: Deprecated. Remove later.
    getMerchantAsSeriesV2,
    getMerchantAsCountV2,
    getTopListAnalytics,
    getCardsProcessingStatus,
    getCardsActivationsummary,
    exportAnalyticsReport,
    getNewReturnMemberSeries,
    getNewReturnMemberCount,
    getMemberPointsBreakdownBySubTransactions,
    getPointsAllTimeCard,
    getPointsOverviewCounts,
    getPointsOverviewSeries,
    getPointsSummary,
    exportPointsOverviewSeries,
    exportPointsSummarySeries,
};
